<!--简历管理-->
<template>
  <el-container>
    <el-header>
    <!-- 搜索框     -->
      <el-input placeholder="请输入内容"
                @change="operation"
                v-model="queryInfo.keyword"
                class="input-with-select">
        <el-button slot="append"
                   icon="el-icon-search" @click="operation"></el-button>
      </el-input>
    </el-header>

    <el-main>
      <el-table
        ref="cvTable"
        highlight-current-row
        v-loading="loading"
        :border="true"
        :data="cvList"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          align="center"
          prop="appliedPosition"
          label="应聘岗位"
        ></el-table-column>

        <el-table-column
          align="center"
          prop="name"
          label="姓名"
        ></el-table-column>

        <el-table-column
          align="center"
          prop="gender"
          label="性别"
        ></el-table-column>

        <el-table-column
          align="center"
          prop="telNo"
          label="电话"
        ></el-table-column>

        <!-- :disabled="scope.row.totalScore !== null" -->
        <el-table-column align="center" label="操作" width="300">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-view"
              size="small"
              @click="toInfoDetail(scope.row)"
            >
              查看详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!--分页-->
      <el-pagination
        style="margin-top: 25px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-main>

    <el-dialog
      title="个人信息"
      :visible.sync="userInfoDialog"
      :close-on-click-modal="false"
      center
      width="70%"
    >
      <el-button type="primary" style="float: right" @click="exportInfo"
        >导出</el-button
      >
      <div style="clear: both"></div>
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="110px"
        :inline="true"
      >
        <div style="font-weight: bold">基本信息</div>
        <el-form-item
          label="应聘岗位："
          prop="appliedPosition"
          style="position: relative"
          class="form-class2"
        >
          <el-input
            :disabled="isView"
            style="width: 220px"
            v-model="ruleForm.appliedPosition"
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item
          label="姓名："
          prop="name"
          style="position: relative"
          class="form-class2"
        >
          <el-input
            :disabled="isView"
            style="width: 220px"
            v-model="ruleForm.name"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="身份证号码："
          prop="idNo"
          style="position: relative"
          class="form-class2"
        >
          <el-input
            :disabled="isView"
            style="width: 220px"
            v-model="ruleForm.idNo"
            @input="
              ruleForm.idNo = ruleForm.idNo.replace(
                /^\D*(\d*(?:\.\d*)?).*$/g,
                '$1'
              )
            "
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item
          label="性别："
          prop="gender"
          style="position: relative"
          class="form-class2"
        >
          <el-radio-group
            style="width: 220px"
            :disabled="isView"
            v-model="ruleForm.gender"
          >
            <el-radio
              v-for="(item, index) in sexArray"
              :label="item.id"
              :key="index"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="籍贯："
          prop="nativePlace"
          style="position: relative"
          class="form-class2"
        >
          <el-input
            :disabled="isView"
            style="width: 220px"
            v-model="ruleForm.nativePlace"
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item
          label="民族："
          prop="nation"
          style="position: relative"
          class="form-class2"
        >
          <el-input
            :disabled="isView"
            style="width: 220px"
            v-model="ruleForm.nation"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="电话："
          prop="telNo"
          style="position: relative"
          class="form-class2"
        >
          <el-input
            :disabled="isView"
            style="width: 220px"
            v-model="ruleForm.telNo"
            @input="
              ruleForm.telNo = ruleForm.telNo.replace(
                /^\D*(\d*(?:\.\d*)?).*$/g,
                '$1'
              )
            "
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item
          label="年龄："
          prop="age"
          style="position: relative"
          class="form-class2"
        >
          <el-input
            :disabled="isView"
            style="width: 220px"
            v-model="ruleForm.age"
            @input="
              ruleForm.age = ruleForm.age.replace(
                /^\D*(\d*(?:\.\d*)?).*$/g,
                '$1'
              )
            "
          ></el-input>
        </el-form-item>

        <el-form-item
          label="身高："
          prop="height"
          style="position: relative"
          class="form-class2"
        >
          <el-input
            :disabled="isView"
            style="width: 220px"
            v-model="ruleForm.height"
            @input="
              ruleForm.height = ruleForm.height.replace(
                /^\D*(\d*(?:\.\d*)?).*$/g,
                '$1'
              )
            "
          >
            <template slot="append">m</template>
          </el-input>
        </el-form-item>
        <br />
        <el-form-item
          label="体重："
          prop="weight"
          style="position: relative"
          class="form-class2"
        >
          <el-input
            :disabled="isView"
            style="width: 220px"
            v-model="ruleForm.weight"
            @input="
              ruleForm.weight = ruleForm.weight.replace(
                /^\D*(\d*(?:\.\d*)?).*$/g,
                '$1'
              )
            "
          >
            <template slot="append">kg</template>
          </el-input>
        </el-form-item>

        <el-form-item
          label="最高学历："
          prop="highestEducation"
          style="position: relative"
          class="form-class2"
        >
          <el-input
            :disabled="isView"
            style="width: 220px"
            v-model="ruleForm.highestEducation"
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item
          label="最高学位："
          prop="highestDegree"
          style="position: relative"
          class="form-class2"
        >
          <el-input
            :disabled="isView"
            style="width: 220px"
            v-model="ruleForm.highestDegree"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="个人禁忌："
          prop="personalTaboo"
          style="position: relative"
          class="form-class2"
        >
          <el-radio-group
            style="width: 220px"
            :disabled="isView"
            v-model="ruleForm.personalTaboo"
            @input="ruleForm.personalTabooDetail = ''"
          >
            <el-radio
              v-for="(item, index) in isNoArray"
              :label="item.id"
              :key="index"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
          <el-input
            v-if="ruleForm.personalTaboo == 2"
            :disabled="isView"
            style="width: 90px; position: absolute; top: 0; left: 130px"
            v-model="ruleForm.personalTabooDetail"
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item
          label="政治面貌："
          prop="political"
          style="position: relative"
          class="form-class2"
        >
          <el-input
            :disabled="isView"
            style="width: 220px"
            v-model="ruleForm.political"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="加入时间："
          prop="joinTime"
          style="position: relative"
          class="form-class2"
        >
          <el-date-picker
            disabled
            style="width: 220px"
            v-model="ruleForm.joinTime"
            type="month"
            placeholder="选择月"
            value-format="yyyy-MM"
          >
          </el-date-picker>
        </el-form-item>
        <br />
        <el-form-item
          label="婚姻状况："
          prop="maritalStatus"
          style="position: relative"
          class="form-class2"
        >
          <el-radio-group
            style="width: 260px"
            :disabled="isView"
            v-model="ruleForm.maritalStatus"
            @input="ruleForm.otherMarital = ''"
          >
            <el-radio
              v-for="(item, index) in marryArray"
              :label="item.id"
              :key="index"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
          <el-input
            v-if="ruleForm.maritalStatus == 4"
            :disabled="isView"
            style="width: 170px; position: absolute; top: 0; right: -220px"
            v-model="ruleForm.otherMarital"
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item
          label="居住地址："
          prop="address"
          style="position: relative"
          class="form-class2"
        >
          <el-input
            :disabled="isView"
            style="width: 220px"
            v-model="ruleForm.address"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="通勤时长："
          prop="commuteHours"
          style="position: relative"
          class="form-class2"
        >
          <el-input
            :disabled="isView"
            style="width: 220px"
            v-model="ruleForm.commuteHours"
          >
            <template slot="append">分钟</template>
          </el-input>
        </el-form-item>
        <br />
        <el-form-item
          label="能否出差："
          prop="businessTravel"
          style="position: relative"
          class="form-class2"
        >
          <el-radio-group
            style="width: 220px"
            :disabled="isView"
            v-model="ruleForm.businessTravel"
          >
            <el-radio label="能">能</el-radio>
            <el-radio label="否">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="能否加班："
          prop="workOvertime"
          style="position: relative"
          class="form-class2"
        >
          <el-radio-group
            style="width: 220px"
            :disabled="isView"
            v-model="ruleForm.workOvertime"
          >
            <el-radio label="能">能</el-radio>
            <el-radio label="否">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <br />
        <el-form-item
          label="能否接受公司调动工作地点："
          prop="relocation"
          style="position: relative"
          class="form-class2 long-label"
        >
          <el-radio-group
            style="width: 220px"
            :disabled="isView"
            v-model="ruleForm.relocation"
          >
            <el-radio label="能">能</el-radio>
            <el-radio label="否">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <br />
        <el-form-item
          label="原月收入(税前,元)："
          prop="monthlyIncome"
          style="position: relative"
          class="form-class2 middle-label"
        >
          <el-input
            :disabled="isView"
            style="width: 220px"
            v-model="ruleForm.monthlyIncome"
            @input="
              ruleForm.monthlyIncome = ruleForm.monthlyIncome.replace(
                /^\D*(\d*(?:\.\d*)?).*$/g,
                '$1'
              )
            "
          ></el-input>
        </el-form-item>

        <el-form-item
          label="原年收入(税前,万元)："
          prop="annualIncome"
          style="position: relative"
          class="form-class2 middle-label"
        >
          <el-input
            :disabled="isView"
            style="width: 220px"
            v-model="ruleForm.annualIncome"
            @input="
              ruleForm.annualIncome = ruleForm.annualIncome.replace(
                /^\D*(\d*(?:\.\d*)?).*$/g,
                '$1'
              )
            "
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item
          label="原社保公积金缴纳基数："
          prop="cardinal"
          style="position: relative"
          class="form-class2 middle-label"
        >
          <el-input
            :disabled="isView"
            style="width: 220px"
            v-model="ruleForm.cardinal"
            @input="
              ruleForm.cardinal = ruleForm.cardinal.replace(
                /^\D*(\d*(?:\.\d*)?).*$/g,
                '$1'
              )
            "
          ></el-input>
        </el-form-item>
        <el-form-item
          label="期望薪资(税前,元)："
          prop="expectSalary"
          style="position: relative"
          class="form-class2 middle-label"
        >
          <el-input
            :disabled="isView"
            style="width: 220px"
            v-model="ruleForm.expectSalary"
            @input="
              ruleForm.expectSalary = ruleForm.expectSalary.replace(
                /^\D*(\d*(?:\.\d*)?).*$/g,
                '$1'
              )
            "
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item
          label="现与其它单位是否存在劳动关系?"
          prop="laborRelation"
          style="position: relative"
          class="form-class2 top-label"
        >
          <el-radio-group
            style="width: 220px"
            :disabled="isView"
            v-model="ruleForm.laborRelation"
            @input="ruleForm.companyName = ''"
          >
            <el-radio :label="1">否</el-radio>
            <el-radio :label="2">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <br />
        <el-form-item
          label="单位名称："
          prop="companyName"
          style="position: relative"
          class="form-class2 middle-label"
          v-if="ruleForm.laborRelation == 2"
        >
          <el-input
            :disabled="isView"
            style="width: 220px"
            v-model="ruleForm.companyName"
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item
          label="是否曾涉及民、刑事诉讼案件或其他相关法律案件？"
          prop="legalCase"
          style="position: relative"
          class="form-class2 top-label"
        >
          <el-radio-group
            style="width: 220px"
            :disabled="isView"
            v-model="ruleForm.legalCase"
            @input="ruleForm.caseDetail = ''"
          >
            <el-radio :label="1">否</el-radio>
            <el-radio :label="2">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <br />
        <el-form-item
          label="具体事件："
          prop="caseDetail"
          style="position: relative"
          class="form-class2 middle-label"
          v-if="ruleForm.legalCase == 2"
        >
          <el-input
            :disabled="isView"
            style="width: 220px"
            v-model="ruleForm.caseDetail"
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item
          label="有无职业病、精神疾病、慢性病 、遗传病或其他重大病史？"
          prop="medicalHistory"
          style="position: relative"
          class="form-class2 top-top-label"
        >
          <el-radio-group
            style="width: 110px"
            :disabled="isView"
            v-model="ruleForm.medicalHistory"
            @input="ruleForm.medicalHistoryDetail = ''"
          >
            <el-radio :label="1">无</el-radio>
            <el-radio :label="2">有</el-radio>
          </el-radio-group>
          <el-input
            v-if="ruleForm.medicalHistory == 2"
            :disabled="isView"
            style="width: 170px; position: absolute; top: 0; left: 130px"
            v-model="ruleForm.medicalHistoryDetail"
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item
          label="有无亲属在本公司？"
          prop="hasKnowPeople"
          style="position: relative"
          class="form-class2 top-label"
        >
          <el-radio-group
            style="width: 220px"
            :disabled="isView"
            v-model="ruleForm.hasKnowPeople"
            @input="knowObject = { name: '', part: '', relation: '' }"
          >
            <el-radio :label="1">无</el-radio>
            <el-radio :label="2">有</el-radio>
          </el-radio-group>
        </el-form-item>
        <br />
        <el-form-item
          label="姓名："
          prop=""
          style="position: relative"
          class="form-class2 middle-label"
          v-if="ruleForm.hasKnowPeople == 2"
        >
          <el-input
            :disabled="isView"
            style="width: 220px"
            v-model="knowObject.name"
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item
          label="所在部门："
          prop=""
          style="position: relative"
          class="form-class2 middle-label"
          v-if="ruleForm.hasKnowPeople == 2"
        >
          <el-input
            :disabled="isView"
            style="width: 220px"
            v-model="knowObject.part"
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item
          label="与本人关系："
          prop=""
          style="position: relative"
          class="form-class2 middle-label"
          v-if="ruleForm.hasKnowPeople == 2"
        >
          <el-input
            :disabled="isView"
            style="width: 220px"
            v-model="knowObject.relation"
          ></el-input>
        </el-form-item>
        <br />
       
        <el-form-item
          label="个人擅长工作模块："
          prop="adeptWork"
          style="position: relative"
          class="form-class2 middle-label"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            :disabled="isView"
            style="width: 500px; word-break: break-all"
            v-model="ruleForm.adeptWork"
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item
          label="个人核心/亮眼业绩："
          prop="personalAchievement"
          style="position: relative"
          class="form-class2 middle-label"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            :disabled="isView"
            style="width: 500px; word-break: break-all"
            v-model="ruleForm.personalAchievement"
          ></el-input>
        </el-form-item>
        <br />

        <el-form-item
          label="个人希望进阶/补充工作模块："
          prop="desireImprovingSkills"
          style="position: relative"
          class="form-class2 middle-label"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            :disabled="isView"
            style="width: 500px; word-break: break-all"
            v-model="ruleForm.desireImprovingSkills"
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item
          label="新一年的职业目标："
          prop="careerObjective"
          style="position: relative"
          class="form-class2 middle-label"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            :disabled="isView"
            style="width: 500px; word-break: break-all"
            v-model="ruleForm.careerObjective"
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item
          label="个人优点："
          prop="advantage"
          style="position: relative"
          class="form-class2 middle-label"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            :disabled="isView"
            style="width: 220px; word-break: break-all"
            v-model="ruleForm.advantage"
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item
          label="个人缺点："
          prop="shortcoming"
          style="position: relative"
          class="form-class2 middle-label"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            :disabled="isView"
            style="width: 220px; word-break: break-all"
            v-model="ruleForm.shortcoming"
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item
          label="个人特长、技能与爱好："
          prop="hobby"
          style="position: relative"
          class="form-class2 middle-label"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            :disabled="isView"
            style="width: 220px; word-break: break-all"
            v-model="ruleForm.hobby"
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item
          label="获得过何种奖励或荣誉："
          prop="honor"
          style="position: relative"
          class="form-class2 middle-label"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            :disabled="isView"
            style="width: 220px; word-break: break-all"
            v-model="ruleForm.honor"
          ></el-input>
        </el-form-item>
        <br />
        <div style="font-weight: bold">
          <span style="color: #f56c6c">*</span>学习经历
          （请先填写最高学历相关信息）
        </div>
        <div
          style="padding: 20px; border: 1px solid #dcdfe6; margin-bottom: 22px"
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 15px;
            "
          >
            <div style="width: 28%; text-align: center">起止时间</div>
            <div style="width: 20%; text-align: center">学校名称</div>
            <div style="width: 20%; text-align: center">所学专业</div>
            <div style="width: 20%; text-align: center">毕业/结业/肄业</div>
          </div>
          <div v-for="(item3, index3) in educateArray" :key="index3">
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;
              "
            >
              <div style="width: 28%; text-align: center">
                <el-date-picker
                  disabled
                  v-model="item3.timeArray"
                  type="monthrange"
                  range-separator="至"
                  start-placeholder="开始月份"
                  end-placeholder="结束月份"
                  style="width: 100%"
                  value-format="yyyy-MM"
                >
                </el-date-picker>
              </div>
              <div style="width: 20%; text-align: center">
                <el-input
                  :disabled="isView"
                  style="width: 100%"
                  v-model="item3.schoolName"
                ></el-input>
              </div>
              <div style="width: 20%; text-align: center">
                <el-input
                  :disabled="isView"
                  style="width: 100%"
                  v-model="item3.major"
                ></el-input>
              </div>
              <div style="width: 20%; text-align: center">
                <el-input
                  :disabled="isView"
                  style="width: 100%"
                  v-model="item3.status"
                ></el-input>
              </div>
            </div>
          </div>
        </div>

        <div style="font-weight: bold">
          <span style="color: #f56c6c">*</span>工作经历 （请先填写最近的经历）
        </div>
        <div
          style="padding: 20px; border: 1px solid #dcdfe6; margin-bottom: 22px"
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 15px;
            "
          >
            <div style="width: 28%; text-align: center">起止时间</div>
            <div style="width: 20%; text-align: center">工作单位</div>
            <div style="width: 10%;" class="td-class">规模（人数、营收）</div>
            <div style="width: 10%;" class="td-class">职务</div>
            <div style="width: 12%; text-align: center">职务</div>
            <div style="width: 12%; text-align: center">所在城市</div>
            <div style="width: 12%; text-align: center">离职薪资</div>
            <div style="width: 20%; text-align: center">离职原因</div>
          </div>
          <div v-for="(item3, index3) in workArray" :key="index3">
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;
              "
            >
              <div style="width: 28%; text-align: center">
                <el-date-picker
                  disabled
                  v-model="item3.timeArray"
                  type="monthrange"
                  range-separator="至"
                  start-placeholder="开始月份"
                  end-placeholder="结束月份"
                  style="width: 100%"
                  value-format="yyyy-MM"
                >
                </el-date-picker>
              </div>
              <div style="width: 20%; text-align: center">
                <el-input
                  :disabled="isView"
                  style="width: 100%"
                  v-model="item3.companyName"
                ></el-input>
              </div>
              <div style="width: 14%; " class="td-class">
                <el-input
                  :disabled="isView"
                  style="width: 100%"
                  v-model="item3.careerSector"
                ></el-input>
              </div>
              <div style="width: 14%; " class="td-class">
                <el-input
                  :disabled="isView"
                  style="width: 100%"
                  v-model="item3.companyScale"
                ></el-input>
              </div>
              <div style="width: 12%; text-align: center">
                <el-input
                  :disabled="isView"
                  style="width: 100%"
                  v-model="item3.post"
                ></el-input>
              </div>
              <div style="width: 12%; text-align: center">
                <el-input
                  :disabled="isView"
                  style="width: 100%"
                  v-model="item3.city"
                ></el-input>
              </div>
              <div style="width: 12%; text-align: center">
                <el-input
                  :disabled="isView"
                  style="width: 100%"
                  v-model="item3.lastSalary"
                ></el-input>
              </div>
              <div style="width: 20%; text-align: center">
                <el-input
                  :disabled="isView"
                  style="width: 100%"
                  v-model="item3.reason"
                ></el-input>
              </div>
            </div>
          </div>
        </div>

        <div style="font-weight: bold">
          <span style="color: #f56c6c">*</span>社会关系（请填写父母、配偶）
        </div>
        <div
          style="padding: 20px; border: 1px solid #dcdfe6; margin-bottom: 22px"
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 15px;
            "
          >
            <div style="width: 14%; text-align: center">姓名</div>
            <div style="width: 14%; text-align: center">年龄</div>
            <div style="width: 12%; text-align: center">与本人关系</div>
            <div style="width: 20%; text-align: center">工作单位及职务</div>
            <div style="width: 20%; text-align: center">联系方式</div>
          </div>
          <div v-for="(item3, index3) in relationArray" :key="index3">
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;
              "
            >
              <div style="width: 14%; text-align: center">
                <el-input
                  :disabled="isView"
                  style="width: 100%"
                  v-model="item3.name"
                ></el-input>
              </div>
              <div style="width: 14%; text-align: center">
                <el-input
                  :disabled="isView"
                  style="width: 100%"
                  v-model="item3.age"
                  @input="
                    item3.age = item3.age.replace(
                      /^\D*(\d*(?:\.\d*)?).*$/g,
                      '$1'
                    )
                  "
                ></el-input>
              </div>
              <div style="width: 12%; text-align: center">
                <el-input
                  :disabled="isView"
                  style="width: 100%"
                  v-model="item3.relation"
                ></el-input>
              </div>
              <div style="width: 20%; text-align: center">
                <el-input
                  :disabled="isView"
                  style="width: 100%"
                  v-model="item3.companyNamePost"
                ></el-input>
              </div>
              <div style="width: 20%; text-align: center">
                <el-input
                  :disabled="isView"
                  style="width: 100%"
                  v-model="item3.contact"
                ></el-input>
              </div>
            </div>
          </div>
        </div>

        <el-form-item
          label="了解到本次招聘信息的途径："
          prop="knowWay"
          style="position: relative"
          class="form-class2 long-label"
        >
          <el-input
            :disabled="isView"
            style="width: 300px"
            v-model="ruleForm.knowWay"
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item
          label="预计到职日期："
          prop="checkDate"
          style="position: relative"
          class="form-class2 long-label"
        >
          <!-- <el-input
            :disabled="isView"
            style="width: 300px"
            v-model="ruleForm.checkDate"
          ></el-input> -->
          <el-date-picker
            disabled
            style="width: 300px"
            v-model="ruleForm.checkDate"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <br />
      </el-form>
      <div style="font-weight: bold">个人声明</div>
      <div style="margin-top: 10px">
        1、本人（<el-radio-group :disabled="isView" v-model="ruleForm.agree">
          <el-radio :label="1">同意</el-radio>
          <el-radio :label="2">不同意</el-radio> </el-radio-group
        >）公司对以上相关信息予以调查核实；
      </div>
      <div style="margin-top: 10px">
        2、本人保证以上所填各项内容均属实。若有不实或虚构，愿承担取消录用资格或入职后被解除劳动关系等相关法律责任。
      </div>
      <div style="margin-top: 30px; text-align: right">
        签名：
        <!-- <el-button v-if="!resultImg" type="primary" @click="toSign"
          >去签名</el-button
        > -->
        <img :src="resultImg" style="width: 100px; height: 40px" />
      </div>
      <div style="margin-top: 10px; text-align: right">
        日期：<el-date-picker
          :disabled="isView"
          v-model="ruleForm.signTime"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="returnBackInfo">返 回</el-button>
        <!-- <el-button type="primary" @click="submitInfo">提交</el-button> -->
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import cv from "@/api/curriculumVaitae";
import exam from "@/api/exam";

export default {
  name: "CvManage",
  data() {
    const checkPhone = (rule, value, callback) => {
      if (!/^1(2|3|4|5|6|7|8|9)\d{9}$/.test(value)) {
        return callback("请输入正确的手机号");
      }
      callback();
    };
    return {
      rowId: "",
      knowObject: {
        name: "",
        part: "",
        relation: "",
      },
      resultImg: "",
      educateArray: [
        {
          schoolName: "",
          startTime: "",
          endTime: "",
          timeArray: [],
          major: "",
          status: "",
        },
        {
          schoolName: "",
          startTime: "",
          endTime: "",
          timeArray: [],
          major: "",
          status: "",
        },
        {
          schoolName: "",
          startTime: "",
          endTime: "",
          timeArray: [],
          major: "",
          status: "",
        },
      ],
      workArray: [
        {
          companyName: "",
          startTime: "",
          endTime: "",
          timeArray: [],
          city: "",
          lastSalary: "",
          post: "",
          reason: "",
        },
        {
          companyName: "",
          startTime: "",
          endTime: "",
          timeArray: [],
          city: "",
          lastSalary: "",
          post: "",
          reason: "",
        },
        {
          companyName: "",
          startTime: "",
          endTime: "",
          timeArray: [],
          city: "",
          lastSalary: "",
          post: "",
          reason: "",
        },
        {
          companyName: "",
          startTime: "",
          endTime: "",
          timeArray: [],
          city: "",
          lastSalary: "",
          post: "",
          reason: "",
        },
      ],
      relationArray: [
        {
          name: "",
          age: "",
          relation: "",
          companyNamePost: "",
          contact: "",
        },
        {
          name: "",
          age: "",
          relation: "",
          companyNamePost: "",
          contact: "",
        },
        {
          name: "",
          age: "",
          relation: "",
          companyNamePost: "",
          contact: "",
        },
      ],
      marryArray: [
        { id: 1, name: "未婚" },
        { id: 2, name: "已婚" },
        { id: 3, name: "离异" },
        { id: 4, name: "其他" },
      ],
      sexArray: [
        { id: 1, name: "男" },
        { id: 2, name: "女" },
      ],
      isNoArray: [
        { id: 1, name: "无" },
        { id: 2, name: "有" },
      ],
      isView: false,
      ruleForm: {
        caseDetail: "",
        medicalHistoryDetail: "",
        companyName: "",
        knowPeopleInfo: "",
        otherMarital: "",
        appliedPosition: "",
        name: "",
        idNo: "",
        gender: "",
        nativePlace: "",
        nation: "",
        telNo: "",
        age: "",
        height: "",
        weight: "",
        highestEducation: "",
        highestDegree: "",
        personalTaboo: "",
        personalTabooDetail: "",
        political: "",
        joinTime: "",
        maritalStatus: "",
        address: "",
        commuteHours: "",
        businessTravel: "",
        workOvertime: "",
        relocation: "",
        monthlyIncome: "",
        annualIncome: "",
        cardinal: "",
        expectSalary: "",
        laborRelation: "",
        legalCase: "",
        medicalHistory: "",
        hasKnowPeople: "",
        hobby: "",
        adeptWork: "",
        personalAchievement: "",
        desireImprovingSkills: "",
        careerObjective: "",
        advantage: "",
        shortcoming: "",
        honor: "",
        learningExperienceList: [
          // {
          //   schoolName: "",
          //   startTime: "",
          //   endTime: "",
          //   major: "",
          //   status: "",
          // },
        ],
        workExperienceList: [
          // {
          //   companyName: "",
          //   startTime: "",
          //   endTime: "",
          //   city: "",
          //   post: "",
          //   reason: "",
          // },
        ],
        socialRelationList: [
          // {
          //   name: "",
          //   age: "",
          //   relation: "",
          //   companyNamePost: "",
          //   contact: "",
          // },
        ],
        knowWay: "",
        checkDate: "",
        agree: "",
        signTime: "",
        signName: "",
      },
      userInfoDialog: false,
      queryInfo: {
        pageNo: 1,
        pageSize: 10,
        keyword:'',
      },
      //考试记录
      cvList: [],
      //表格数据加载
      loading: true,
      //总数
      total: 0,
    };
  },
  created() {
    this.listCvPage();
  },
  methods: {
    currentTimer() {
      const now = new Date();
      let year = now.getFullYear();
      let month = now.getMonth() + 1;
      let day = now.getDate();
      let hour = now.getHours();
      let minutes = now.getMinutes();
      month = month <= 9 ? "0" + month : month;
      hour = hour <= 9 ? "0" + hour : hour;
      day = day <= 9 ? "0" + day : day;
      minutes = minutes <= 9 ? "0" + minutes : minutes;
      return year + "-" + month + "-" + day + "-" + hour + "" + minutes;
    },
    exportInfo() {
      exam
        .exportInfo(this.rowId)
        .then((res) => {
          console.log(res);
          var blob = res;
          let timer = this.currentTimer();
          let fileName = `${timer}.docx`;
          if ("msSaveOrOpenBlob" in navigator) {
            window.navigator.msSaveOrOpenBlob(blob, fileName); //IE导出
          } else {
            let url = window.URL.createObjectURL(
              new Blob([blob], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
              })
            );
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", fileName);
            link.click();
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    returnBackInfo() {
      this.userInfoDialog = false;
      this.knowObject = {
        name: "",
        part: "",
        relation: "",
      };
      this.educateArray = [
        {
          schoolName: "",
          startTime: "",
          endTime: "",
          timeArray: [],
          major: "",
          status: "",
        },
        {
          schoolName: "",
          startTime: "",
          endTime: "",
          timeArray: [],
          major: "",
          status: "",
        },
        {
          schoolName: "",
          startTime: "",
          endTime: "",
          timeArray: [],
          major: "",
          status: "",
        },
      ];
      this.workArray = [
        {
          companyName: "",
          startTime: "",
          endTime: "",
          timeArray: [],
          city: "",
          lastSalary: "",
          careerSector: "",
          companyScale: "",
          post: "",
          reason: "",
        },
        {
          companyName: "",
          startTime: "",
          endTime: "",
          timeArray: [],
          city: "",
          lastSalary: "",
          careerSector: "",
          companyScale: "",
          post: "",
          reason: "",
        },
        {
          companyName: "",
          startTime: "",
          endTime: "",
          timeArray: [],
          city: "",
          lastSalary: "",
          careerSector: "",
          companyScale: "",
          post: "",
          reason: "",
        },
        {
          companyName: "",
          startTime: "",
          endTime: "",
          timeArray: [],
          city: "",
          lastSalary: "",
          careerSector: "",
          companyScale: "",
          post: "",
          reason: "",
        },
      ];
      this.relationArray = [
        {
          name: "",
          age: "",
          relation: "",
          companyNamePost: "",
          contact: "",
        },
        {
          name: "",
          age: "",
          relation: "",
          companyNamePost: "",
          contact: "",
        },
        {
          name: "",
          age: "",
          relation: "",
          companyNamePost: "",
          contact: "",
        },
      ];
      this.ruleForm = {
        caseDetail: "",
        medicalHistoryDetail: "",
        companyName: "",
        knowPeopleInfo: "",
        otherMarital: "",
        appliedPosition: "",
        name: "",
        idNo: "",
        gender: "",
        nativePlace: "",
        nation: "",
        telNo: "",
        age: "",
        height: "",
        weight: "",
        highestEducation: "",
        highestDegree: "",
        personalTaboo: "",
        personalTabooDetail: "",
        political: "",
        joinTime: "",
        maritalStatus: "",
        address: "",
        commuteHours: "",
        businessTravel: "",
        workOvertime: "",
        relocation: "",
        monthlyIncome: "",
        annualIncome: "",
        cardinal: "",
        expectSalary: "",
        laborRelation: "",
        legalCase: "",
        medicalHistory: "",
        hasKnowPeople: "",
        hobby: "",
        adeptWork: "",
        personalAchievement: "",
        desireImprovingSkills: "",
        careerObjective: "",
        advantage: "",
        shortcoming: "",
        honor: "",
        learningExperienceList: [
          // {
          //   schoolName: "",
          //   startTime: "",
          //   endTime: "",
          //   major: "",
          //   status: "",
          // },
        ],
        workExperienceList: [
          // {
          //   companyName: "",
          //   startTime: "",
          //   endTime: "",
          //   city: "",
          //   post: "",
          //   reason: "",
          // },
        ],
        socialRelationList: [
          // {
          //   name: "",
          //   age: "",
          //   relation: "",
          //   companyNamePost: "",
          //   contact: "",
          // },
        ],
        knowWay: "",
        checkDate: "",
        agree: "",
        signTime: "",
        signName: "",
      };
    },
    toInfoDetail(row) {
      this.userInfoDialog = true;
      this.rowId = row.id;
      exam.getUserInfo(row.id).then((resp) => {
        if (resp.code === 200) {
          this.ruleForm = resp.data;
          this.resultImg = resp.data.signUrl;
          this.educateArray = [];
          this.workArray = [];
          this.relationArray = [];
          this.knowObject = {
            name: "",
            part: "",
            relation: "",
          };
          if (resp.data.hasKnowPeople == 2) {
            this.knowObject.name = resp.data.knowPeopleInfo.split(",")[0];
            this.knowObject.part = resp.data.knowPeopleInfo.split(",")[1];
            this.knowObject.relation = resp.data.knowPeopleInfo.split(",")[2];
          }
          resp.data.learningExperienceList.forEach((item) => {
            this.educateArray.push({
              schoolName: item.schoolName,
              startTime: item.startTime,
              endTime: item.endTime,
              timeArray: [item.startTime, item.endTime],
              major: item.major,
              status: item.status,
            });
          });
          resp.data.workExperienceList.forEach((item) => {
            this.workArray.push({
              companyName: item.companyName,
              startTime: item.startTime,
              endTime: item.endTime,
              timeArray: [item.startTime, item.endTime],
              city: item.city,
              lastSalary: item.lastSalary,
              careerSector: item.careerSector,
          companyScale: item.companyScale,
              post: item.post,
              reason: item.reason,
            });
          });
          resp.data.socialRelationList.forEach((item) => {
            this.relationArray.push({
              name: item.name,
              age: item.age,
              relation: item.relation,
              companyNamePost: item.companyNamePost,
              contact: item.contact,
            });
          });
          this.$nextTick(() => {
            this.isView = true;
          });
        }
      });
    },
    toExamDetail(row) {
      if (row.totalScore === null) {
        this.$router.push("/markExam/" + row.recordId + "/1");
      } else {
        this.$router.push("/markExam/" + row.recordId + "/2");
      }
    },
    async listCvPage() {
      await cv.listCv(this.queryInfo).then((resp) => {
        if (resp.code === 200) {
          this.cvList = resp.data.data;
          this.total = resp.data.total;
          this.loading = false;
        }
      });
    },
    operation(v) {
      console.log(v);
      if (v === "" ||  this.queryInfo.keyword.length<2) {
        return;
      }
      console.log(this.queryInfo.keyword);
      this.listCvPage();
    },
    //分页页面大小改变
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.listCvPage();
    },
    //分页插件的页数
    handleCurrentChange(val) {
      this.queryInfo.pageNo = val;
      this.listCvPage();
    },
  },
};
</script>
<style scoped>
.form-class2 {
  margin-right: 20px;
}
.form-class2 >>> .el-form-item__content {
  width: 220px;
}
.top-label >>> .el-form-item__label {
  width: 350px !important;
  text-align: left;
  display: block !important;
}
.top-top-label >>> .el-form-item__label {
  width: 395px !important;
  text-align: left;
  display: block !important;
}
.long-label >>> .el-form-item__label {
  width: 230px !important;
  /* text-align: left;
  display: block !important; */
}
/* .long-label >>> .el-form-item__label label {
  display: block;
}
.top-label >>> .el-form-item__label label {
  display: block;
}
.top-top-label >>> .el-form-item__label label {
  display: block;
} */
.middle-label >>> .el-form-item__label {
  width: 180px !important;
  /* text-align: left; */
}
</style>
<style scoped lang="scss">
@import "../../assets/css/teacher/markManage";
</style>
