import request from '@/utils/request'
export default {

  listCv (params) {
    return request({
      url: '/curriculumVitae/page',
      method: 'get',
      params: params
    })
  },



}
